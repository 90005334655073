import PropTypes from "prop-types";
import Image from "@primitives/image";
import { Stack } from "@washingtonpost/site-components";
import Link from "next/link";
import {
  CardWrapper,
  CardWrapperSimple,
  CardInner,
  CardEyebrow,
  CardNumber,
  CardTitle,
  CardText,
  ClickableCard,
  CardArrow
} from "./styles";

const CardDefault = ({
  className,
  image,
  eyebrow,
  number,
  title,
  text,
  url
}) => {
  const Component = (
    <CardWrapper className={className}>
      {image && (
        <Image
          src={image.url}
          height={image.height}
          width={image.width}
          alt={title}
        />
      )}
      <CardInner>
        {eyebrow && (
          <CardEyebrow className="font--body-tiny">{eyebrow}</CardEyebrow>
        )}
        {number && (
          <CardNumber className="font--body-tiny">{`/ ${number}`}</CardNumber>
        )}
        {title && <CardTitle className="font--heading-sm">{title}</CardTitle>}
        {text && <CardText dangerouslySetInnerHTML={{ __html: text }} />}
        {url && <CardArrow direction="right" variant="red" />}
      </CardInner>
    </CardWrapper>
  );

  if (url) {
    return (
      <Link href={url} passHref>
        <ClickableCard className={className}>{Component}</ClickableCard>
      </Link>
    );
  }

  return Component;
};

CardDefault.propTypes = {
  className: PropTypes.string,
  number: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired
  }),
  eyebrow: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string
};

const CardSimple = ({ className, number, image, title, text }) => {
  return (
    <CardWrapperSimple className={className}>
      <CardInner sm>
        {image && (
          <Image
            src={image?.url}
            height={image?.height}
            width={image?.width}
            alt={title}
          />
        )}
        <Stack space="xs">
          {number && (
            <CardNumber className="font--body-tiny">{`/ ${number}`}</CardNumber>
          )}
          {title && <CardTitle className="font--heading-sm">{title}</CardTitle>}
          {text && <CardText dangerouslySetInnerHTML={{ __html: text }} />}
        </Stack>
      </CardInner>
    </CardWrapperSimple>
  );
};

CardSimple.propTypes = {
  className: PropTypes.string,
  number: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  title: PropTypes.string,
  text: PropTypes.string
};

const Card = ({
  className,
  image,
  number,
  eyebrow,
  title,
  text,
  url,
  type
}) => {
  if (type && "simple" === type) {
    return (
      <CardSimple
        className={className}
        eyebrow={eyebrow}
        number={number}
        title={title}
        text={text}
        url={url}
        image={image}
      />
    );
  }

  return (
    <CardDefault
      className={className}
      eyebrow={eyebrow}
      number={number}
      image={image}
      title={title}
      text={text}
      url={url}
    />
  );
};

Card.propTypes = {
  className: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    height: PropTypes.string,
    width: PropTypes.string
  }),
  number: PropTypes.string,
  eyebrow: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.oneOf(["default", "simple"])
};

export default Card;
