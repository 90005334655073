import React, { useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import classnames from "classnames";
import Fade from "@primitives/fade";
import { Stack } from "@washingtonpost/site-components";
import Section from "@primitives/section";
import Container from "@primitives/container";
import { useBreakpoints } from "@context/breakpoints";
import { theme } from "@src/theme";

const Wrapper = styled(Section).attrs(({ alternate, centered }) => ({
  className: classnames({
    alternate,
    centered
  })
}))`
  background-color: ${theme.colors.blueLight};
  color: ${theme.colors.red};
  height: 80vh;
  justify-content: flex-end;
  max-height: 800px;
  min-height: 600px;
  padding: ${theme.spacing.md} 0;

  @media screen and (max-width: 460px) {
    min-height: ${(props) => props?.textHeight + 500}px;
  }

  h2 {
    color: ${theme.colors.greenDark};
  }

  &.alternate {
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};

    h2 {
      color: ${theme.colors.creamLight};
    }
  }

  &.centered {
    text-align: center;
  }
`;

const WrapperContainer = styled(Container)`
  margin-bottom: ${(props) => (parseInt(props?.words) >= 10 ? "1rem" : "3rem")};
  margin-top: 2rem;
  position: relative;

  @media ${theme.breakpoints.large} {
    margin-bottom: ${(props) =>
      parseInt(props?.words) >= 10 ? "2rem" : "6rem"};
    margin-top: 0;
  }
`;

const Inner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Title = styled.h3``;

const Headline = styled.h2`
  --headline-min-font-size: ${(props) =>
    (props?.words > 10 ? 160 : 170) / props?.words}rem;
  --headline-max-font-size: 20rem;
  --headline-scaler: 0.8rem + 17%;

  @supports (
    font-size:
      clamp(
        var(--headline-min-font-size),
        var(--headline-scaler),
        var(--headline-max-font-size)
      )
  ) {
    font-size: clamp(
      var(--headline-min-font-size),
      var(--headline-scaler),
      var(--headline-max-font-size)
    );
  }

  bottom: 0;
  display: inline-flex;
  left: 0;
  line-height: 0.5;
  opacity: 0.25;
  position: absolute;
  transform: translateX(-2vw);
  text-transform: uppercase;
  white-space: nowrap;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
`;

const SectionCallout = ({ alternate, centered, eyebrow, text, heading }) => {
  const breakpoints = useBreakpoints();
  const textRef = useRef();
  const [textHeight, setTextHeight] = React.useState();
  const titleClass = breakpoints.large
    ? "font--heading-xl"
    : "font--heading-lg";

  React.useEffect(() => {
    setTextHeight(textRef.current.offsetHeight);
  }, []);

  return (
    <Wrapper
      alternate={alternate}
      centered={centered}
      words={text.length}
      textHeight={textHeight}
    >
      {heading && <Headline words={heading.length}>{heading}</Headline>}
      <WrapperContainer inner>
        <Inner ref={textRef}>
          <Fade>
            <Stack space="sm">
              {eyebrow && <p className="font--eyebrow">{eyebrow}</p>}
              {text && <Title className={titleClass}>{text}</Title>}
            </Stack>
          </Fade>
        </Inner>
      </WrapperContainer>
    </Wrapper>
  );
};

SectionCallout.propTypes = {
  alternate: PropTypes.bool,
  centered: PropTypes.bool,
  eyebrow: PropTypes.string,
  text: PropTypes.string,
  heading: PropTypes.string
};

export default SectionCallout;
