import React from "react";
import PropTypes from "prop-types";
import { useBreakpoints } from "@context/breakpoints";
import Fade from "@primitives/fade";
import Grid from "@primitives/grid";
import Stat from "@primitives/stat";
import { BottomButtonCarousel } from "@primitives/carousel/BottomButtonCarousel";

const Metrics = ({ items, columns, small }) => {
  const breakpoints = useBreakpoints();

  if (!small) {
    small = false;
  }

  const gridMetrics = (
    <Grid columns={columns}>
      {items?.map((stat, index) => (
        <Fade key={index}>
          <Stat
            key={index}
            small={small}
            citation={stat.citation}
            label={stat.label}
            total={stat.total}
            prefix={stat.prefix}
            number={stat.number}
            suffix={stat.suffix}
          />
        </Fade>
      ))}
    </Grid>
  );

  const sliderMetrics = (
    <BottomButtonCarousel>
      {items.map((stat, index) => (
        <Stat
          key={index}
          citation={stat.citation}
          label={stat.label}
          total={stat.total}
          prefix={stat.prefix}
          number={stat.number}
          suffix={stat.suffix}
        />
      ))}
    </BottomButtonCarousel>
  );

  // Always show the grid metrics if items are two or less
  const metrics = breakpoints.notSmall ? (
    <React.Fragment>{gridMetrics}</React.Fragment>
  ) : (
    <React.Fragment>
      {items?.length > 2 ? <>{sliderMetrics}</> : <>{gridMetrics}</>}
    </React.Fragment>
  );

  return <React.Fragment>{metrics}</React.Fragment>;
};

Metrics.propTypes = {
  items: PropTypes.array.isRequired,
  columns: PropTypes.number,
  small: PropTypes.bool,
  sectionHeading: PropTypes.object
};

export default Metrics;
