import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styled from "styled-components";
import BaseArrow from "@images/arrow.svg";
import { useBreakpoints } from "@context/breakpoints";
import { theme } from "@src/theme";

// CSS clamp is used to scale a value from min to max based on viewport width.
// The values were obtained by using the calculator here: https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/#for-those-who-dont-mind-that-edge-case

// export const rowHeight = "clamp(99px, 82.3333px + 4.6296vw, 149px)";
export const rowHeight = "clamp(59px, 40.3333px + 5.4296vw, 150px)";

export const Wrapper = styled.div.attrs(({ animated }) => ({
  className: classnames({
    animated
  })
}))`
  left: 0;
  padding: ${rowHeight} 0;
  transition-property: all;
  transition-duration: ${theme.animations.duration.medium};
  animation-timing-function: ${theme.animations.timingFunction.enter};

  &.animated {
    transform: translateY(-100%);
    animation-timing-function: ${theme.animations.timingFunction.exit};
  }
`;

const Text = styled.h3(({ theme }) => ({
  borderBottomColor: theme.colors.red,
  borderBottomStyle: "solid",
  borderBottomWidth: 1,
  top: "190px",
  position: "absolute",
  width: "100%",

  [`@media ${theme.breakpoints.medium}`]: {
    top: "100px"
  },

  [`@media ${theme.breakpoints.large}`]: {
    top: "50vh",
    position: "absolute",
    transform: "translateY(-50%)"
  }
}));

const Arrow = styled(BaseArrow)(({ theme }) => ({
  height: "clamp(32px, 20.6667px + 2.7037vw, 108px)",
  marginLeft: theme.spacing.xs,
  marginRight: theme.spacing.xs,
  width: "clamp(32px, 20.6667px + 2.7037vw, 108px)",

  [`@media ${theme.breakpoints.medium}`]: {
    marginRight: 30
  },

  [`@media ${theme.breakpoints.large}`]: {
    marginRight: 50
  }
}));

const LineWrapper = styled.span(({ theme }) => ({
  borderTopColor: theme.colors.red,
  borderTopStyle: "solid",
  borderTopWidth: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  width: "100%"
}));

const Line = styled.span(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  fontSize: "clamp(26px, 22.7098px + 3.8407vw, 32px)",
  fontWeight: 700,
  justifyContent: "center",
  lineHeight: rowHeight,
  textAlign: "center",
  textTransform: "uppercase",
  WebkitTextFillColor: "transparent",
  WebkitTextStrokeWidth: 1,

  [`@media ${theme.breakpoints.notSmall}`]: {
    fontSize: "clamp(50px, 32.6923px + 4.3269vw, 80px)"
  },

  [`@media ${theme.breakpoints.large}`]: {
    fontSize: "clamp(50px, 38.6923px + 2.3269vw, 75px)"
  },

  [`@media ${theme.breakpoints.max}`]: {
    fontSize: "clamp(60px, 42.6923px + 4.3269vw, 94px)"
  }
}));

const Emphasized = styled.em(({ theme }) => ({
  color: theme.colors.red,
  fontFamily: theme.fonts.playfair,
  fontWeight: 400,
  marginLeft: theme.spacing.xs,
  marginRight: theme.spacing.xs,
  position: "relative",
  textTransform: "capitalize",
  top: -6,
  WebkitTextFillColor: theme.colors.red,

  [`@media ${theme.breakpoints.large}`]: {
    marginLeft: theme.spacing.sm,
    marginRight: theme.spacing.sm
  }
}));

const WordsSmall = () => (
  <Text>
    <LineWrapper>
      <Line>Our mission is to</Line>
    </LineWrapper>

    <LineWrapper>
      <Line>
        empower <Emphasized>influential</Emphasized>
      </Line>
    </LineWrapper>

    <LineWrapper>
      <Line>audiences across</Line>
    </LineWrapper>

    <LineWrapper>
      <Line>
        the world with <Arrow />
      </Line>
    </LineWrapper>
    <LineWrapper>
      <Line>stories that leave</Line>
    </LineWrapper>

    <LineWrapper>
      <Line>
        a lasting<Emphasized>impact</Emphasized>
      </Line>
    </LineWrapper>
  </Text>
);

const WordsMedium = () => (
  <Text id="words-medium">
    <LineWrapper>
      <Line>Our mission</Line>
    </LineWrapper>
    <LineWrapper>
      <Line>is to empower</Line>
    </LineWrapper>
    <LineWrapper>
      <Line>
        <Emphasized>influential</Emphasized>audiences
      </Line>
    </LineWrapper>
    <LineWrapper>
      <Line>across the world</Line>
    </LineWrapper>
    <LineWrapper>
      <Line>
        with stories <Arrow />
      </Line>
    </LineWrapper>
    <LineWrapper>
      <Line>that leave</Line>
    </LineWrapper>

    <LineWrapper>
      <Line>
        a lasting<Emphasized>impact</Emphasized>
      </Line>
    </LineWrapper>
  </Text>
);

const WordsLarge = () => {
  return (
    <Text id="words-large">
      <LineWrapper>
        <Line>Our mission is to empower</Line>
      </LineWrapper>
      <LineWrapper>
        <Line>
          <Emphasized>influential</Emphasized>
          audiences across
        </Line>
      </LineWrapper>
      <LineWrapper>
        <Line>
          the world with stories
          <Arrow />
        </Line>
      </LineWrapper>
      <LineWrapper>
        <Line>
          that leave a lasting<Emphasized>impact</Emphasized>
        </Line>
      </LineWrapper>
    </Text>
  );
};

const Words = ({ animated }) => {
  const breakpoints = useBreakpoints();

  if (breakpoints.medium) {
    return (
      <Wrapper animated={animated}>
        <WordsMedium />
      </Wrapper>
    );
  }

  if (breakpoints.large) {
    return (
      <Wrapper animated={animated}>
        <WordsLarge />
      </Wrapper>
    );
  }

  return (
    <Wrapper animated={animated}>
      <WordsSmall />
    </Wrapper>
  );
};

Words.propTypes = {
  animated: PropTypes.bool.isRequired
};

export default Words;
