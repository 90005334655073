import styled from "styled-components";
import { theme } from "@src/theme";
import {
  CircledArrowButton,
  redVariantHoverStyles
} from "@primitives/buttons/CircledArrowButton";

export const cardWidth = "300px";
export const spaceBetweenCardsSmall = 16;
export const spaceBetweenCardsNotSmall = 48;

export const CardArrow = styled(CircledArrowButton)`
  margin-top: auto;
`;

export const CardWrapper = styled.div`
  background-color: ${theme.colors.white};
  display: flex;
  height: 100%;
  flex-direction: column;
  max-width: ${cardWidth};
  transition: box-shadow 150ms ease-in-out;

  &:hover {
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25),
      4px 4px 12px 0px rgba(0, 0, 0, 0.25);

    ${CardArrow} {
      ${redVariantHoverStyles}
    }
  }
`;

export const CardInner = styled.div`
  align-items: flex-start;
  border: 1px solid ${theme.colors.cream};
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${theme.spacing.md} ${theme.spacing.xs} ${theme.spacing.xs};
  width: 100%;

  ${CardWrapper}:hover & {
    border-color: ${theme.colors.black};
  }
`;

export const CardWrapperSimple = styled.div`
  background-color: ${theme.colors.white};
  display: flex;
  height: 100%;
  max-width: ${cardWidth};

  p {
    margin-bottom: 0;
  }
`;

export const CardEyebrow = styled.p`
  margin-bottom: 12px;
`;

export const CardNumber = styled.span``;

export const CardTitle = styled.h3`
  margin-bottom: 2rem;
`;

export const CardText = styled.p`
  margin-bottom: 2rem;
  margin-top: -1rem; // assumes that there's always a title when there's text
`;

export const CardLink = styled.a`
  bottom: ${theme.spacing.xs};
  display: block;
  position: absolute;
`;

export const ClickableCard = styled.a`
  color: inherit;
  display: block;
  height: 100%;
`;
