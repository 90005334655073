import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styled from "styled-components";
import Fade from "@primitives/fade";
import Image from "@primitives/image";
import ContentSplit from "@primitives/contentSplit";
import { ArrowLink } from "@primitives/arrowLink";
import { Stack } from "@washingtonpost/site-components";
import { theme } from "@src/theme";
import { LinkButton } from "@primitives/buttons";

const TopBorderWrap = styled.div`
  border-top: 1px solid ${theme.colors.red};
  display: flex;
  flex-direction: column;
  padding-top: ${theme.spacing.md};
`;

const WrapperImage = styled.div.attrs(({ reversed }) => ({
  className: classnames({
    reversed
  })
}))`
  display: flex;
  max-width: calc(100% - ${theme.spacing.md});
  margin: 0 auto ${theme.spacing.sm} calc(${theme.spacing.sm} * -1);

  @media ${theme.breakpoints.large} {
    margin: 0 auto 0 0;
  }

  &.reversed {
    justify-content: flex-end;
    margin: 0 calc(${theme.spacing.sm} * -1) ${theme.spacing.sm} auto;

    @media ${theme.breakpoints.large} {
      margin: 0 0 0 auto;
    }
  }
`;

const ContentWrapper = styled.div`
  max-width: 42em;

  @media ${theme.breakpoints.large} {
    max-width: 32.75em;
  }
`;

const ContentTextWrapper = styled.div`
  p:not(:last-of-type) {
    margin-bottom: ${theme.spacing.md};
  }

  b,
  strong {
    display: block;
  }
`;

const ContentCtaWrapper = styled.div`
  margin-top: ${theme.spacing.md};
`;

const ContentTitle = styled.h3`
  margin-bottom: ${theme.spacing.sm};
  max-width: 11em;

  span {
    hyphens: auto;
    word-break: break-word;
  }
`;

const ContentText = ({ text }) => {
  if (Array.isArray(text)) {
    return (
      <ContentTextWrapper>
        {text?.map((paragraph, index) => (
          <p dangerouslySetInnerHTML={{ __html: paragraph }} key={index} />
        ))}
      </ContentTextWrapper>
    );
  }

  return <p dangerouslySetInnerHTML={{ __html: text }} />;
};

ContentText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

const ContentCta = ({ cta, type }) => {
  const target = cta?.target ? cta.target : "_self";
  return (
    <>
      {cta ? (
        <ContentCtaWrapper>
          {type === "cta" ? (
            <LinkButton external={cta.external} href={cta.url} target={target}>
              {cta.title}
            </LinkButton>
          ) : (
            <ArrowLink href={cta.url} target={target}>
              {cta.title}
            </ArrowLink>
          )}
        </ContentCtaWrapper>
      ) : null}
    </>
  );
};

ContentCta.propTypes = {
  cta: PropTypes.shape({
    external: PropTypes.bool,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    target: PropTypes.string
  }),
  type: PropTypes.string
};

const ContentBlockMain = ({
  title,
  text,
  cta,
  bottom,
  centered,
  reversed,
  buttonType
}) => {
  return (
    <ContentSplit bottom={bottom} centered={centered} reversed={reversed}>
      <div>
        {title && (
          <Fade>
            <ContentTitle className="font--heading-lg">
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </ContentTitle>
          </Fade>
        )}
      </div>
      <div>
        <Fade delay={0.2}>
          <ContentText text={text} />
          <ContentCta type={buttonType} cta={cta} />
        </Fade>
      </div>
    </ContentSplit>
  );
};

ContentBlockMain.propTypes = {
  buttonType: PropTypes.oneOf(["link", "cta"]),
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  bottom: PropTypes.bool,
  centered: PropTypes.bool,
  reversed: PropTypes.bool,
  cta: ContentCta.propTypes.cta
};

const ContentBlockNumber = ({
  number,
  title,
  text,
  bottom,
  centered,
  reversed
}) => {
  return (
    <ContentSplit
      bottom={bottom}
      centered={centered}
      reversed={reversed}
      gap={true}
    >
      <TopBorderWrap>
        <Fade>
          <Stack space="xs">
            {number && <p>{number}</p>}
            {title && (
              <ContentTitle className="font--heading-lg">{title}</ContentTitle>
            )}
          </Stack>
        </Fade>
      </TopBorderWrap>
      {text && (
        <Fade>
          <ContentText text={text} />
        </Fade>
      )}
    </ContentSplit>
  );
};

ContentBlockNumber.propTypes = {
  number: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  bottom: PropTypes.bool,
  centered: PropTypes.bool,
  reversed: PropTypes.bool
};

const ContentBlockImage = ({
  bottom,
  centered,
  reversed,
  image,
  title,
  text,
  cta,
  buttonType
}) => {
  return (
    <ContentSplit bottom={bottom} centered={centered} reversed={reversed}>
      <div>
        {image && (
          <Fade>
            <WrapperImage reversed={reversed}>
              <Image
                src={image?.url}
                alt={image?.title}
                height={image?.height}
                width={image?.width}
              />
            </WrapperImage>
          </Fade>
        )}
      </div>

      <ContentWrapper>
        <Fade>
          {title && (
            <ContentTitle className="font--heading-lg">
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </ContentTitle>
          )}
          <ContentText text={text} />
          <ContentCta type={buttonType} cta={cta} />
        </Fade>
      </ContentWrapper>
    </ContentSplit>
  );
};

ContentBlockImage.propTypes = {
  buttonType: PropTypes.oneOf(["link", "cta"]),
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired
  }),
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  cta: ContentCta.propTypes.cta,
  bottom: PropTypes.bool,
  centered: PropTypes.bool,
  reversed: PropTypes.bool
};

const ContentBlock = ({
  bottom,
  centered,
  reversed,
  type,
  image,
  number,
  title,
  text,
  cta,
  buttonType
}) => {
  if (!buttonType) {
    buttonType = "link";
  }

  if (type && "number" === type) {
    return (
      <ContentBlockNumber
        buttonType={buttonType}
        number={number}
        title={title}
        text={text}
        bottom={bottom}
        centered={centered}
        reversed={reversed}
      />
    );
  }

  if (type && "image" === type) {
    return (
      <ContentBlockImage
        buttonType={buttonType}
        image={image}
        title={title}
        text={text}
        cta={cta}
        bottom={bottom}
        centered={centered}
        reversed={reversed}
      />
    );
  }

  return (
    <ContentBlockMain
      buttonType={buttonType}
      title={title}
      text={text}
      cta={cta}
      bottom={bottom}
      centered={centered}
      reversed={reversed}
    />
  );
};

ContentBlock.propTypes = {
  type: PropTypes.oneOf(["number", "image"]),
  buttonType: PropTypes.oneOf(["link", "cta"]),
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired
  }),
  number: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  cta: ContentCta.propTypes.cta,
  bottom: PropTypes.bool,
  centered: PropTypes.bool,
  reversed: PropTypes.bool
};

export default ContentBlock;
