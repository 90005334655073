import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import useDeviceDetect from "@helpers/useDeviceDetect";
import { basePath } from "../../lib/basePath";

const Video = ({ url, height, width, loop, muted, playsInline }) => {
  const { isMobile } = useDeviceDetect();
  const videoRef = useRef(null);

  const onMobilePlay = useCallback(() => {
    videoRef.current?.setAttribute("autoplay", "");
    videoRef.current?.play();
  }, []);

  const onMouseOver = useCallback(() => videoRef.current.play(), []);
  const onMouseOut = useCallback(() => videoRef.current.pause(), []);

  useEffect(() => {
    if (isMobile) {
      onMobilePlay();
    }
  }, [isMobile, onMobilePlay]);

  useEffect(() => {
    videoRef.current?.addEventListener("mouseover", onMouseOver);
    videoRef.current?.addEventListener("mouseout", onMouseOut);
    const ref = videoRef.current;

    return () => {
      ref.current?.removeEventListener("mouseover", onMouseOver);
      ref.current?.removeEventListener("mouseout", onMouseOut);
    };
  }, [onMouseOver, onMouseOut, isMobile]);

  const videoEl = (
    <video
      ref={videoRef}
      height={height}
      width={width}
      muted={muted}
      loop={loop}
      playsInline={playsInline}
    >
      <source src={`${basePath}${url}`} type="video/mp4" />
    </video>
  );

  return videoEl;
};

Video.propTypes = {
  url: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  playsInline: PropTypes.bool
};

export default Video;
