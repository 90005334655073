import React from "react";
import styled from "styled-components";
import { Stack } from "@washingtonpost/site-components";
import Container, {
  OffsetRightContainer,
  ThreeFourthContainer,
  FullWidthContainer
} from "@primitives/container";
import ContentSplit, {
  ContentSplitSectionHeadingWrapper
} from "@primitives/contentSplit";
import Fade from "@primitives/fade";
import Section from "@primitives/section";
import SectionHeading from "@primitives/sectionHeading";
import Callout from "@components/callout";
import Cards from "@components/cards";
import ContentBlock from "@components/contentBlock";
import HeroHome from "@components/heroHome";
import Metrics from "@components/metrics";
import SectionCallout from "@components/sectionCallout";
import Steps from "@components/steps";
import SuccessStories from "@components/successStories";
import { useLayout } from "@context/layout";
import { theme } from "@src/theme";
import { Spacer } from "@styles/general";
import content from "@data/homepage.json";

const Boxes = styled.div`
  display: flex;
  flex-direction: column;

  @media ${theme.breakpoints.large} {
    flex-direction: row;
  }
`;

export const Box = styled.div`
  --box-spacing: 3rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50vh;
  flex: 0 0 100%;
  padding: var(--box-spacing) 0;
  position: relative;

  @media ${theme.breakpoints.large} {
    --box-spacing: 8rem;
    flex: 0 0 50%;

    &:nth-child(even) {
      padding: 3rem 0 3rem var(--box-spacing);
    }

    &:nth-child(odd) {
      padding: 3rem var(--box-spacing) 3rem 0;

      > div {
        margin-left: auto;
      }
    }

    > div {
      max-width: calc(
        calc(var(--max-width-lg) / 2) - calc(var(--box-spacing) * 1.3)
      );
    }
  }
`;

export const Icon = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);

  @media ${theme.breakpoints.large} {
    left: 0;
    top: 50%;
  }
`;

const Index = () => {
  const {
    audienceCards,
    callout,
    contentBlock,
    heroBoxes,
    metrics,
    sectionCallout,
    steps,
    solutionsCards,
    successStories,
    footerCallout
  } = content;
  const { setFooterCalloutContent, setNavVariant } = useLayout();

  React.useEffect(() => {
    setFooterCalloutContent(footerCallout);
    setNavVariant("dark");
  }, [footerCallout, setFooterCalloutContent, setNavVariant]);

  return (
    <>
      <FullWidthContainer>
        <HeroHome />
      </FullWidthContainer>

      <FullWidthContainer>
        <Container inner>
          {heroBoxes?.length > 0 && (
            <Boxes>
              {heroBoxes.map((item, index) => (
                <Box key={index}>
                  {index == 1 && (
                    <Icon>
                      <svg
                        width="84"
                        height="84"
                        viewBox="0 0 84 84"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M82.75 54.7177H83.25V54.2177V29.6926V29.1926H82.75H55.3588V1V0.5H54.8588H28.8912H28.3912V1V29.1926H1H0.5V29.6926V54.2177V54.7177H1H28.3912V82.75V83.25H28.8912H54.8588H55.3588V82.75V54.7177H82.75Z"
                          stroke="#E73106"
                        />
                      </svg>
                    </Icon>
                  )}
                  <div>
                    <Stack space="sm">
                      {item?.title && (
                        <h3 className="font--heading-lg">{item.title}</h3>
                      )}
                      {item?.text && <p>{item.text}</p>}
                    </Stack>
                  </div>
                </Box>
              ))}
            </Boxes>
          )}
        </Container>
      </FullWidthContainer>

      <FullWidthContainer>
        <SectionCallout
          eyebrow={sectionCallout?.eyebrow}
          text={sectionCallout?.text}
          heading={sectionCallout?.heading}
        />
      </FullWidthContainer>

      <Spacer sm />

      <Section>
        <ThreeFourthContainer>
          <Steps items={steps?.items} />
        </ThreeFourthContainer>
      </Section>

      <Spacer xl />

      <OffsetRightContainer>
        <ContentBlock
          bottom
          reversed
          type="image"
          image={contentBlock?.image}
          title={contentBlock?.title}
          text={contentBlock?.text}
        />
      </OffsetRightContainer>

      <Spacer xl />

      <FullWidthContainer>
        <Callout
          alternative
          title={callout?.title}
          description={callout?.description}
          cta={callout?.cta}
        />
      </FullWidthContainer>

      <Spacer xl />

      <Section>
        <ContentSplit>
          <ContentSplitSectionHeadingWrapper>
            <SectionHeading
              eyebrow={metrics?.sectionHeading?.eyebrow}
              title={metrics?.sectionHeading?.title}
              text={metrics?.sectionHeading?.text}
            />
          </ContentSplitSectionHeadingWrapper>
          <Metrics columns={2} items={metrics?.items} />
        </ContentSplit>
      </Section>

      <Spacer xl />

      <Section>
        <Container inner>
          <SectionHeading
            eyebrow={audienceCards?.sectionHeading?.eyebrow}
            title={audienceCards?.sectionHeading?.title}
            text={audienceCards?.sectionHeading?.text}
          />
          <Fade>
            <Cards equalHeight items={audienceCards?.items} />
          </Fade>
        </Container>
      </Section>

      <Spacer xl />

      <Section>
        <Container inner>
          <SectionHeading
            eyebrow={solutionsCards?.sectionHeading?.eyebrow}
            title={solutionsCards?.sectionHeading?.title}
            text={solutionsCards?.sectionHeading?.text}
          />
          <Fade>
            <Cards equalHeight items={solutionsCards?.items} />
          </Fade>
        </Container>
      </Section>

      <Spacer xl />

      <Section>
        <SuccessStories
          items={successStories?.items}
          eyebrow={successStories?.eyebrow}
          heading={successStories?.heading}
        />
      </Section>
    </>
  );
};

export default Index;
