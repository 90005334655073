import styled from "styled-components";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useBreakpoints } from "@context/breakpoints";
import Image from "@primitives/image";
import Video from "./video";
import { theme } from "@src/theme";
import { imageContent, videoContent } from "./data";

const Wrapper = styled.div.attrs(({ animated }) => ({
  className: classnames({
    animated
  })
}))`
  left: 50%;
  max-width: calc(var(--max-width) + ${theme.spacing.md});
  transform: translateX(-50%);

  > div {
    transition-property: all;
    transition-duration: ${theme.animations.duration.medium};
    animation-timing-function: ${theme.animations.timingFunction.enter};
  }

  &.animated {
    animation-timing-function: ${theme.animations.timingFunction.exit};
  }

  video {
    cursor: pointer;
    height: 100%;
    width: 100%;
  }
`;

const HuluImage = styled.div.attrs(({ animated }) => ({
  className: classnames({
    animated
  })
}))`
  max-width: 265px;
  position: absolute;
  right: 0;
  top: -25vh;

  @media ${theme.breakpoints.large} {
    max-width: 600px;
  }

  &.animated {
    top: 5vh;
  }
`;

const MikesImage = styled.div.attrs(({ animated }) => ({
  className: classnames({
    animated
  })
}))`
  bottom: -10vh;
  max-width: 200px;
  position: absolute;
  right: 0;
  transform: translate(25%, 0%);
  z-index: 3;

  @media ${theme.breakpoints.large} {
    bottom: -15vh;
    max-width: 285px;
    right: 10vh;
    transform: translate(0);
  }

  &.animated {
    bottom: 0;

    @media ${theme.breakpoints.large} {
      bottom: 15vh;
    }
  }
`;

const NiImage = styled.div.attrs(({ animated }) => ({
  className: classnames({
    animated
  })
}))`
  left: 0;
  max-width: 200px;
  position: absolute;
  transform: translateX(-25%);
  top: -15vh;
  z-index: 4;

  @media ${theme.breakpoints.notSmall} {
    bottom: -25vh;
    left: 20%;
    max-width: 300px;
    top: auto;
    transform: translate(0);
  }

  &.animated {
    top: 15vh;

    @media ${theme.breakpoints.notSmall} {
      bottom: 5vh;
      top: auto;
    }
  }
`;

const AttVideo = styled.div.attrs(({ animated }) => ({
  className: classnames({
    animated
  })
}))`
  max-width: 237px;
  position: absolute;
  top: -80vh;
  width: 40vw;
  z-index: 3;

  @media ${theme.breakpoints.large} {
    max-width: 531px;
  }

  &.animated {
    top: 10vh;
  }
`;

const MgmVideo = styled.div.attrs(({ animated }) => ({
  className: classnames({
    animated
  })
}))`
  left: 50%;
  max-width: 162px;
  position: absolute;
  top: -80vh;
  transform: translate(-50%, -50%);

  width: 50vw;
  z-index: 3;

  @media ${theme.breakpoints.large} {
    max-width: 237px;
  }

  &.animated {
    top: 50%;
  }
`;

const NiVideo = styled.div.attrs(({ animated }) => ({
  className: classnames({
    animated
  })
}))`
  bottom: -25vh;
  max-width: 267px;
  position: absolute;
  transform: translateX(-20%);
  width: 50vw;
  z-index: 4;

  @media ${theme.breakpoints.large} {
    bottom: -15vh;
    max-width: 316px;
  }

  &.animated {
    bottom: 8vh;

    @media ${theme.breakpoints.large} {
      bottom: 10vh;
    }
  }
`;

const Media = ({ animated }) => {
  const breakpoints = useBreakpoints();

  return (
    <Wrapper animated={animated}>
      {breakpoints?.notSmall && (
        <AttVideo animated={animated}>
          <Video
            url={videoContent?.att?.url}
            height={videoContent?.att?.height}
            width={videoContent?.att?.width}
            loop={true}
            muted={true}
            playsInline={true}
          />
        </AttVideo>
      )}
      <MgmVideo animated={animated}>
        <Video
          url={videoContent?.mgm?.url}
          height={videoContent?.mgm?.height}
          width={videoContent?.mgm?.width}
          loop={true}
          muted={true}
          playsInline={true}
        />
      </MgmVideo>
      <HuluImage animated={animated}>
        <Image
          src={imageContent.hulu.url}
          height={imageContent?.hulu?.height}
          width={imageContent?.hulu?.width}
        />
      </HuluImage>

      <NiVideo animated={animated}>
        <Video
          url={videoContent?.ni?.url}
          height={videoContent?.ni?.height}
          width={videoContent?.ni?.width}
          loop={true}
          muted={true}
          playsInline={true}
        />
      </NiVideo>
      <NiImage animated={animated}>
        <Image
          src={imageContent.ni.url}
          height={imageContent?.ni?.height}
          width={imageContent?.ni?.width}
        />
      </NiImage>
      <MikesImage animated={animated}>
        <Image
          src={imageContent.mikes.url}
          height={imageContent?.mikes?.height}
          width={imageContent?.mikes?.width}
        />
      </MikesImage>
    </Wrapper>
  );
};

Media.propTypes = {
  animated: PropTypes.bool.isRequired
};

export default Media;
