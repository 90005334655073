export const imageContent = {
  mikes: {
    url: "/images/homepage/homepage-hero-mikes.gif",
    height: 500,
    width: 500
  },
  hulu: {
    url: "/images/homepage/homepage-hero-hulu.png",
    height: 900,
    width: 1474
  },
  ni: {
    url: "/images/homepage/homepage-hero-ni.png",
    height: 562,
    width: 1000
  }
};

export const videoContent = {
  att: {
    url: "/videos/homepage-hero-att.mp4",
    height: 1350,
    width: 1552
  },
  mgm: {
    url: "/videos/homepage-hero-mgm.mp4",
    height: 1430,
    width: 850
  },
  ni: {
    url: "/videos/homepage-hero-ni.mp4",
    height: 1080,
    width: 1920
  }
};
