import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useBreakpoints } from "@context/breakpoints";
import Fade from "@primitives/fade";
import Card from "@primitives/card";
import Grid from "@primitives/grid";
import { BottomButtonCarousel } from "@primitives/carousel/BottomButtonCarousel";

const Cards = ({ columns, items, type, equalHeight = false }) => {
  const breakpoints = useBreakpoints();
  const ref = useRef(null);

  if (!type) type = "default";

  if (!columns) columns = 3;

  useEffect(() => {
    setTimeout(() => {
      if (equalHeight) {
        let heightsArr = []; // Array that holds all items height
        const childrenEls = ref.current?.children ?? null; // Get all children elements
        const arrayLength = childrenEls?.length; // Get the length of the children elements

        if (!childrenEls) return; // Bail out if empty

        // Loop through all children elements and push their height to the array
        for (let i = 0; i < arrayLength; i++) {
          heightsArr.push(childrenEls[i].offsetHeight);
        }

        // Set a variable equal to the tallest div
        const tallest = Math.max(...heightsArr);

        // Loop through all children elements and set their height to the tallest div
        for (let i = 0; i < arrayLength; i++) {
          childrenEls[i].style.height = tallest + "px";
        }
      }
    }, 500);
  }, [equalHeight]);

  const innerContent = breakpoints.notSmall ? (
    <Fade>
      <Grid columns={columns} innerRef={ref}>
        {items?.map((item, index) => (
          <div key={index}>
            <Card key={index} type={type} {...item} />
          </div>
        ))}
      </Grid>
    </Fade>
  ) : (
    <BottomButtonCarousel>
      {items?.map((item, index) => (
        <Card key={index} type={type} {...item} />
      ))}
    </BottomButtonCarousel>
  );

  return <>{innerContent}</>;
};

Cards.propTypes = {
  type: PropTypes.oneOf(["default", "simple"]),
  items: PropTypes.array,
  columns: PropTypes.number,
  equalHeight: PropTypes.bool
};

export default Cards;
