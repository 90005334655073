import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import styled from "styled-components";
import classnames from "classnames";
import Words from "./words";
import Media from "./media";

gsap.registerPlugin(ScrollTrigger);

const Wrapper = styled.section.attrs(({ fixed }) => ({
  className: classnames({
    fixed
  })
}))`
  height: 200vh;
  position: relative;

  > div {
    bottom: 0;
    height: 100vh;
    position: absolute;
    width: 100%;

    &:nth-child(1) {
      pointer-events: none;
      z-index: 2;
    }
    &:nth-child(2) {
      z-index: 1;
    }
  }

  &.fixed {
    > div {
      position: fixed;
    }
  }
`;

const HeroHome = () => {
  const mainRef = useRef();
  const [animated, setAnimated] = useState(false);
  const [fixed, setFixed] = useState(true);

  useEffect(() => {
    const animation = ScrollTrigger.create({
      trigger: mainRef?.current,
      start: "top top",
      end: "bottom bottom",
      markers: false,
      onEnter: () => {
        setAnimated(true);
      },
      onEnterBack: () => {
        setFixed(true);
      },
      onLeave: () => {
        setFixed(false);
      },
      onLeaveBack: () => {
        setAnimated(false);
      }
    });

    return () => animation.kill();
  }, []);
  return (
    <Wrapper ref={mainRef} fixed={fixed}>
      <Words animated={animated} />
      <Media animated={animated} />
    </Wrapper>
  );
};

export default HeroHome;
