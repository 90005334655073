import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { theme } from "@src/theme";
import { useCountUp } from "use-count-up";
import { isFloat } from "@utils";
import useOnScreen from "@helpers/useOnScreen";

const StatWrapper = styled.div`
  border-top: 1px solid ${theme.colors.red};
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  padding-top: 0.5rem;
`;

const Title = styled.p`
  align-items: flex-end;
  display: flex;

  small {
    line-height: 1.3;
    margin: 0 0.5rem;
  }
`;

const Cite = styled.cite`
  max-width: 18em;
`;

const Stat = ({ citation, label, total, number, prefix, suffix, small }) => {
  const itemRef = useRef();
  const [counting, setCounting] = useState(false);
  const [reveal, setReveal] = useState(false);
  const onScreen = useOnScreen(itemRef);
  const titleClass = small ? "font--heading-xxl" : "font--heading-xxxl";
  const textClass = small ? "font--caption" : "";
  const isNumberFloat = isFloat(Number(number));

  const leadingZero = number.startsWith("0");

  const { value } = useCountUp({
    isCounting: counting,
    start: 1,
    end: Number(number),
    duration: "3",
    easing: "easeOutCubic",
    formatter: (val) => {
      const floored = Math.floor(val);

      if (!leadingZero) return floored;
      return val < 10 ? `0${floored}` : floored;
    }
  });

  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);

  useEffect(() => {
    if (reveal) {
      setCounting(true);
    }
  }, [reveal]);

  let renderedValue = isNumberFloat ? number : value; // Check if the value is float, and show either the count up number or the default one

  return (
    <StatWrapper ref={itemRef}>
      <Title className={titleClass}>
        {total ? (
          <>
            {renderedValue} <small>in</small> {total}
          </>
        ) : (
          <>
            {prefix}
            {renderedValue}
            {suffix}
          </>
        )}
      </Title>
      <p className={textClass}>{label}</p>
      {citation && <Cite className={`${textClass} gray7`}>{citation}</Cite>}
    </StatWrapper>
  );
};

Stat.propTypes = {
  citation: PropTypes.string,
  label: PropTypes.string.isRequired,
  total: PropTypes.string,
  number: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  small: PropTypes.bool
};

export default Stat;
