export function isObjEmpty(obj) {
  if (Object.getOwnPropertyNames) {
    return Object.getOwnPropertyNames(obj).length === 0;
  } else {
    var k;
    for (k in obj) {
      // eslint-disable-next-line no-undef
      if (hasOwnProp(obj, k)) {
        return false;
      }
    }
    return true;
  }
}

/**
 * Get the current value of two numbers based on progress
 *
 * This function is being used for GSAP ScrollTrigger
 *
 * @return  {number}  startingNumber - Is the starting number
 * @return  {number}  endingNumber - Is the ending number
 * @return  {number}  progress - Is the current progress of the animation. Must be between 0-1
 */
export function getCurrentValueBasedOnProgress(
  startingNumber,
  endingNumber,
  progress
) {
  if (startingNumber === endingNumber) {
    return;
  }

  if (endingNumber > startingNumber) {
    return (
      endingNumber -
      Math.abs(endingNumber - startingNumber) * progress
    ).toFixed(2);
  }

  return (
    endingNumber +
    Math.abs(endingNumber - startingNumber) * progress
  ).toFixed(2);
}

/**
 * Check if number is float
 *
 * @param   {[type]}  n  [n description]
 *
 * @return  {[type]}     [return description]
 */
export function isFloat(n) {
  return n === +n && n !== (n | 0);
}

/**
 * Check if number is integer
 *
 * @param   {[type]}  n  [n description]
 *
 * @return  {[type]}     [return description]
 */
export function isInteger(n) {
  return n === +n && n === (n | 0);
}

/**
 * Add Blank Targets to Anchor
 *
 * @param   {[type]}  s  [s description]
 *
 * @return  {[type]}     [return description]
 */
export function addBlankTargets(s) {
  return ("" + s).replace(/<a\s+href=/gi, '<a target="_blank" href=');
}
