import styled from "styled-components";
import PropTypes from "prop-types";
import { Stack } from "@washingtonpost/site-components";
import { theme } from "@src/theme";

const Title = styled.h3`
  max-width: 10em;
`;

export const StepWrapper = styled.div`
  border-top: 1px solid ${theme.colors.red};
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
`;

const Stat = (props) => {
  let { number, title, text, spacing } = props;

  if (typeof spacing === "undefined" && !spacing) {
    spacing = "xs";
  }

  return (
    <StepWrapper>
      <Stack space={spacing}>
        <p className="font--caption">{number}</p>
        <Title className="font--heading-sm pr-xl">
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Title>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Stack>
    </StepWrapper>
  );
};

Stat.propTypes = {
  number: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  spacing: PropTypes.string
};

export default Stat;
