import React from "react";
import PropTypes from "prop-types";
import { useBreakpoints } from "@context/breakpoints";
import Fade from "@primitives/fade";
import Grid from "@primitives/grid";
import Step from "@primitives/step";
import { BottomButtonCarousel } from "@primitives/carousel/BottomButtonCarousel";

const Steps = ({ items }) => {
  const breakpoints = useBreakpoints();

  const innerContent = breakpoints.notSmall ? (
    <Grid columns={3}>
      {items?.map((stat, index) => (
        <Fade key={index}>
          <Step
            number={stat.number}
            title={stat.title}
            text={stat.text}
            total={stat.total}
            index={index}
          />
        </Fade>
      ))}
    </Grid>
  ) : (
    <BottomButtonCarousel>
      {items?.map((stat, index) => (
        <Step
          key={index}
          number={stat.number}
          title={stat.title}
          text={stat.text}
          total={stat.total}
        />
      ))}
    </BottomButtonCarousel>
  );

  return <>{innerContent}</>;
};

Steps.propTypes = {
  items: PropTypes.array
};

export default Steps;
